import React, { useEffect, useState } from 'react';
import {
  Button,
  CircularProgress,
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import useStyles from './styles';
import {
  LOCAL_STORAGE_KEYS,
  PRODUCTS_LIST_TABLE_CELLS_ARRAY,
} from '../../../utils/constants';
import ModalBox from '../../../components/ModalBox/ModalBox';
import MyProductForm from '../../../components/MyProductForm/MyProductForm';
import {
  Product,
  ProductSort,
  useGetMyProductListLazyQuery,
  useImportProductListFromFileMutation,
  useRemoveProductMutation,
} from '../../../generated/graphql';
import { useSnackbar } from 'notistack';
import { LIMIT_ITEM_PAGE } from '../../../utils/helpers';
import FilterSelectLimitItems from '../../../components/_Fields/FilterSelectLimitItems/FilterSelectLimitItems';
import AddIcon from '@material-ui/icons/Add';
import FilterInputSearch from '../../../components/_Fields/FilterInputSearch/FilterInputSearch';
import { isObject } from 'formik';
import { useTranslation } from 'react-i18next';
import { IFilters } from '../../../utils/types';
import FilterSelectWarehouse from '../../../components/_Fields/FilterSelectWarehouse/FilterSelectWarehouse';
import FilterSelectSortingProducts from '../../../components/_Fields/FilterSelectSortingProducts/FilterSelectSortingProducts';
import FilterSelectBySortType from '../../../components/_Fields/FilterSelectBySortType/FilterSelectBySortType';
import TableBodyRequestHandler from '../../../components/TableBodyRequestHandler/TableRequestHandler';
import ProductsListTableRowComponent from '../../../components/ProductsListComponents/ProductsListTableRowComponent/ProductsListTableRowComponent';
import PaginationComponent from '../../../components/PaginationComponent/PaginationComponent';

const itemsLimit = localStorage.getItem(
  LOCAL_STORAGE_KEYS.ITEMS_LIMIT_SHIPMENTS_ALL_HISTORY,
);

const warehouseId = localStorage.getItem(LOCAL_STORAGE_KEYS.WAREHOUSE_ID);

const MyProductList: React.FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const classes = useStyles();
  const [isOpenUnitForm, setIsOpenUnitForm] = useState(false);
  const [editingProduct, setEditingProduct] = useState<null | Product>(null);
  const [copyProduct, setCopyProduct] = useState<null | Product>(null);
  const [products, setProducts] = useState<Product[]>([]);
  const [isOpenConfirmRemove, setOpenConfirmRemove] = useState(false);
  const [productToRemove, setProductToRemove] = useState<Product | null>(null);

  const [filter, setFilter] = useState<IFilters>({
    page: 1,
    itemsLimit: itemsLimit ? +itemsLimit : LIMIT_ITEM_PAGE,
    warehouseId: warehouseId ? warehouseId : '',
    search: '',
  });

  const [productFilter, setProductFilter] = useState<ProductSort>({});

  const [getMyProductListLazyQuery, { data: dataProducts, loading }] =
    useGetMyProductListLazyQuery({
      fetchPolicy: 'network-only',
      onError: (error) => {
        if (error) {
          enqueueSnackbar(error.message, { variant: 'error' });
        }
      },
    });

  const [removeProductMutation] = useRemoveProductMutation();

  const [
    importProductListFromFile,
    { loading: importProductListFromFileLoading },
  ] = useImportProductListFromFileMutation();

  useEffect(() => {
    if (!dataProducts?.getMyProductList?.rows) return;

    dataProducts?.getMyProductList?.rows &&
      setProducts(dataProducts?.getMyProductList?.rows as Product[]);

    // eslint-disable-next-line
  }, [dataProducts]);

  useEffect(() => {
    refetchMyProductList();
    // eslint-disable-next-line
  }, [filter, productFilter]);

  const refetchMyProductList = () => {
    getMyProductListLazyQuery({
      variables: {
        limit: filter.itemsLimit,
        offset: (filter.page! - 1) * filter.itemsLimit!,
        search: filter.search,
        ...(filter.warehouseId && { warehouseId: filter.warehouseId }),
        sort: productFilter,
      },
    });
  };

  const handleRemove = (id: number) => {
    if (id) {
      removeProductMutation({
        variables: {
          productId: id,
        },
      })
        .then((value) => {
          if (value?.data?.removeProduct) {
            refetchMyProductList();
            enqueueSnackbar(t('app.itemSuccessfullyDeleted'), {
              variant: 'success',
            });
          }
        })
        .catch((reason) => {
          enqueueSnackbar(reason.message, { variant: 'error' });
        });
    }
  };

  const selectFileUpload = () => {
    let fileInput = document.getElementById('inputUploadFile');
    if (!fileInput) {
      fileInput = document.createElement('input');
      fileInput.setAttribute('id', 'inputUploadFile');
      fileInput.setAttribute(
        'accept',
        '.xls,.xlsx,.xlsm, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel,application/vnd.ms-excel.sheet.macroEnabled.12',
      );
      fileInput.style.visibility = 'hidden';
      document.body.append(fileInput);
      //@ts-ignore
      fileInput.addEventListener('change', uploadProductListFile, false);
      //@ts-ignore
      fileInput.type = 'file';
    }
    fileInput.click();
  };

  const uploadProductListFile = ({
    target: {
      //@ts-ignore
      validity,
      //@ts-ignore
      files: [file],
    },
  }) => {
    let dataArray = new FormData();
    const inputUploadFile = document.getElementById('inputUploadFile');
    //@ts-ignore
    if (inputUploadFile?.files?.length) {
      //@ts-ignore
      dataArray.append('file', inputUploadFile.files[0]);

      validity.valid &&
        importProductListFromFile({
          variables: {
            file: file,
          },
        })
          .then((value) => {
            if (value?.data?.importProductListFromFile) {
              refetchMyProductList();

              if (value?.data?.importProductListFromFile?.messages?.length) {
                enqueueSnackbar(
                  <div>
                    Файл получен и обработан! Но
                    {value?.data?.importProductListFromFile?.messages.map(
                      (message) => (
                        <div>{message}</div>
                      ),
                    )}
                  </div>,
                  {
                    variant: 'success',
                    autoHideDuration: 3000,
                  },
                );
              } else {
                enqueueSnackbar(t('app.fileReceivedAndProcessed'), {
                  variant: 'success',
                  autoHideDuration: 3000,
                });
              }
            } else {
              enqueueSnackbar(t('app.serverResponseSuccessButError'), {
                variant: 'success',
              });
            }
          })
          .catch((reason) => {
            reason?.message &&
              enqueueSnackbar(reason?.message, { variant: 'error' });
          });
      inputUploadFile.remove();
    }
  };

  useEffect(() => {
    if (!isOpenUnitForm) {
      setCopyProduct(null);
      setEditingProduct(null);
    }
  }, [isOpenUnitForm]);

  return (
    <Container>
      <Typography variant='h2' align='center'>
        {t('app.productsWarehouse').toUpperCase()}
      </Typography>
      <div className={classes.filters}>
        <FilterSelectWarehouse
          loading={loading}
          value={filter.warehouseId as string}
          setFilter={setFilter}
        />

        <FilterSelectSortingProducts
          value={productFilter.type}
          setFilter={setProductFilter}
        />
        {productFilter.type ? (
          <FilterSelectBySortType
            value={productFilter.way}
            setFilter={setProductFilter}
          />
        ) : null}

        <FilterSelectLimitItems
          value={filter.itemsLimit as number}
          setFilter={setFilter}
          localStorageItemsLimitKey={
            LOCAL_STORAGE_KEYS.ITEMS_LIMIT_SHIPMENTS_ALL_HISTORY
          }
        />

        <div className={classes.buttonsForSelected}>
          <Button
            variant='contained'
            className={classes.buttonForSelected}
            onClick={() => {
              setIsOpenUnitForm(true);
            }}
          >
            <AddIcon /> {t('app.add')}
          </Button>
          <Button
            variant='contained'
            startIcon={
              importProductListFromFileLoading && <CircularProgress size={22} />
            }
            onClick={selectFileUpload}
            disabled={importProductListFromFileLoading}
            className={classes.buttonForSelected}
          >
            {t('app.import')}
          </Button>
        </div>
        <FilterInputSearch setFilter={setFilter} />
      </div>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {PRODUCTS_LIST_TABLE_CELLS_ARRAY.map((cell) => (
                <TableCell align='center'>{t(cell)}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableBodyRequestHandler
              dataLength={Number(products?.length)}
              colSpan={PRODUCTS_LIST_TABLE_CELLS_ARRAY.length}
              loadingText={t('app.product2').toLowerCase()}
              loading={loading}
            >
              {products
                ? products.map((product) => (
                    <ProductsListTableRowComponent
                      product={product}
                      setEditingProduct={setEditingProduct}
                      setIsOpenUnitForm={setIsOpenUnitForm}
                      setOpenConfirmRemove={setOpenConfirmRemove}
                      setProductToRemove={setProductToRemove}
                      setCopyProduct={setCopyProduct}
                    />
                  ))
                : null}
            </TableBodyRequestHandler>
          </TableBody>
        </Table>
      </TableContainer>
      <PaginationComponent
        count={dataProducts?.getMyProductList?.count}
        limit={filter?.itemsLimit as number}
        currentPage={Number(filter.page)}
        setFilter={setFilter}
      />
      <ModalBox isOpen={isOpenUnitForm} setOpen={setIsOpenUnitForm}>
        <MyProductForm
          filter={filter}
          refetch={refetchMyProductList}
          editingProduct={editingProduct}
          copyProduct={copyProduct}
          setIsOpenUnitForm={setIsOpenUnitForm}
          setEditingProduct={setEditingProduct}
        />
      </ModalBox>
      <ModalBox
        isOpen={isOpenConfirmRemove && isObject(productToRemove)}
        setOpen={setOpenConfirmRemove}
      >
        <Typography variant='h6'>{t('app.confirmItemDeletion')}</Typography>
        <div className={classes.boxModalButtons}>
          <Button
            variant='contained'
            onClick={() => {
              setOpenConfirmRemove(false);
              setProductToRemove(null);
            }}
            color='secondary'
          >
            {t('app.close')}
          </Button>

          <Button
            variant='contained'
            onClick={() => {
              if (isObject(productToRemove)) {
                handleRemove(Number(productToRemove?.id));
              }
              setOpenConfirmRemove(false);
            }}
            disabled={loading}
          >
            {t('app.delete')}
          </Button>
        </div>
      </ModalBox>
    </Container>
  );
};

export default MyProductList;
